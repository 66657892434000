import React from 'react';

import Layout from '../components/Layout';

const InformationPage = () => {

  return (
    <Layout>
      <div className="container-fluid p-0" id="page-top">
        <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
          <div className="w-100">
            <h1 className="mb-0">Update Your <span className="text-primary">Information</span></h1>
            <p className="mb-5">Please fill out the following form to help us update our records on all Alumni.  Starred fields are required.</p>
            <form action="/information" method="POST">
              <div className="form-group">
                <label htmlFor="firstName">First Name*</label>
                <input type="text" className="form-control" id="firstName" name="firstName" placeholder="First Name" />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name*</label>
                <input type="text" className="form-control" id="lastName" name="lastName" placeholder="Last Name" />
              </div>
              <div className="form-group">
                <label htmlFor="address1">Address 1*</label>
                <input type="text" className="form-control" id="address1" name="address1" placeholder="Address 1" />
              </div>
              <div className="form-group">
                <label htmlFor="address2">Address 2</label>
                <input type="text" className="form-control" id="address2" name="address2" placeholder="Address 2" />
              </div>
              <div className="form-group">
                <label htmlFor="city">City*</label>
                <input type="text" className="form-control" id="city" name="city" placeholder="City" />
              </div>
              <div className="form-group">
                <label htmlFor="state">State*</label>
                <input type="text" className="form-control" id="state" name="state" placeholder="State" />
              </div>
              <div className="form-group">
                <label htmlFor="zip">ZIP*</label>
                <input type="text" className="form-control" id="zip" name="zip" placeholder="ZIP" />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone*</label>
                <input type="tel" className="form-control" id="phone" name="phone" placeholder="(555) 555-5555" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input type="text" className="form-control" id="email" name="email" placeholder="email@example.com" />
              </div>
              <div className="form-group">
                <label htmlFor="initiationYear">Initiation Year*</label>
                <input type="text" className="form-control" id="initiationYear" name="initiationYear" placeholder="Initiation Year" />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-success mb-2">Submit Information</button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Layout>
  )
};

export default InformationPage;
